/* You can add global styles to this file, and also import other style files */
@import '~magnific-popup/dist/magnific-popup';
button:hover,
button:focus,
:focus {
    outline: none!important;
    text-decoration: none!important;
}
.hm-product-icon.active,
.hm-drawer .hm-nav .hm-nav-item a.active{
    color: #fd7097;
}
.hm-drawer .hm-nav .hm-nav-item a.active::after {
    transform: rotate( 0deg );
}
.hm-list.hm-instagram li,
.hm-list.hm-instagram li img{
    width: 100%;
}
.owl-nav div{
    display: flex;
    align-items: center;
    justify-content: center;
}
.product-slider .owl-nav div{
    display: inline-block;
}
.hm-featured-product .nav-tabs .nav-link img{
    background: #f3f3f3;
    width: 50px;
    height: 50px;
    object-fit: contain;
}
.hm-featured-product .tab-content .tab-pane .hm-featured-img img{
    background: #f3f3f3;
}
.faqaccordion .card {
    margin-bottom: 0.9rem;
    border: none;
    color: #212529;
}
  
.faqaccordion .card {
    margin-bottom: 1rem;
}
.faqaccordion .card .card-header{
    border: none;
    border-radius: 0;
    padding: 0;
    text-decoration: none;
}
.card-header .btn{
    width: 100%;
    text-align: left;
    text-decoration: none;
}
.faqaccordion .card-body p {
    margin-bottom: 0;
}
.hm-widget-posts ul li>a, .hm-widget-posts ul li a img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    display: block;
}
.hm-sidebar-list li a {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
/* Counter */
countdown .countdown {
    display: flex;
	width: 100%;
    flex-wrap: wrap;
	justify-content: flex-start!important;
}

countdown .countdown .measurements {
   	padding: 0;
    color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: transparent;
	flex: unset!important;
    text-transform: uppercase;
    font-weight: 600;
    padding-top: 0;
    font-family: 'Baloo Paaji 2', cursive;
    width: 80px;
    height: 80px;
    border: 2px solid #000;
    margin: 2rem 0;
    margin-right: 15px;
}
countdown .countdown .measurements .measurements-number{
    font-size: 1.4rem;
    font-weight: 700;
    margin: 0px;
    font-family: 'Baloo Paaji 2', cursive;
}
countdown .countdown .measurements .measurements-text{
    font-size: 14px;
    font-weight: 600;
    line-height: 26px;
    font-family: 'Baloo Paaji 2', cursive;
    text-transform: uppercase;
    margin: 0px 0 0 0;
    color: #131313;
}
/* White */
countdown.white .countdown .measurements{
    border-color: #fff;
}
countdown.white .countdown .measurements .measurements-number,
countdown.white .countdown .measurements .measurements-text{
    color: #fff;
}
.hm-product-details countdown .countdown{
    flex-wrap: nowrap;
}
.hm-product-details countdown .countdown .measurements{
    margin: 1rem 15px 1rem 0;
}
.nav-tabs .nav-item {
    margin-bottom: -2px;
}
.hm-product-colors label,
.hm-icon-btn{
    position: relative;
}
.hm-product-colors input,
.hm-icon-btn input{
    appearance: none;
}
.hm-icon-btn input + span{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.hm-icon-btn input:checked + span{
    color: #fff;
    background-color: #000;
    border-color: #131313;
}
.hm-product-img-slider .slick-list .slick-track,
    .hm-product-img-slider .slick-list{
    width: 100%;
}
/* Pagination */
pagination-template .ngx-pagination {
	margin: 0 0 2rem;
	justify-content: center;
	display: flex;
	padding-left: 0;
	list-style: none;
}
pagination-template .ngx-pagination li {
	margin: 0;
	position: relative;
}
pagination-template .ngx-pagination li.pagination-next, pagination-template .ngx-pagination li.pagination-previous {
	padding: 0;
	display: block;
}
pagination-template .ngx-pagination li.pagination-next span, pagination-template .ngx-pagination li.pagination-previous span, pagination-template .ngx-pagination li a {
	color: #555b62;
	border: 1px solid #dedede;
	background-color: #fff;
	font-size: 14px;
	font-weight: 400;
	padding: 7px 12px!important;
	position: relative;
	margin-left: 8px;
	display: block;
	line-height: 1.25;
    box-shadow: none;
    border-radius: 5px;
}
pagination-template .ngx-pagination li.current {
	color: #fff;
	border: 1px solid #fd7097;
	background-color: #fd7097; 
	font-size: 14px;
	font-weight: 400;
	padding: 7px 13px!important;
	position: relative;
	margin-left: 8px;
	display: block;
	line-height: 1.25;
    box-shadow: none;
    border-radius: 5px;
}
pagination-template .ngx-pagination li.pagination-next::after, pagination-template .ngx-pagination li.pagination-next a:after, pagination-template .ngx-pagination li.pagination-previous a::before, pagination-template .ngx-pagination li.pagination-previous::before {
	display: none!important;
}
pagination-template .ngx-pagination li.pagination-previous a,
pagination-template .ngx-pagination li.pagination-previous span{
    margin: 0;
}
.hm-shop-filter pagination-template .ngx-pagination{
    margin-bottom: 0;
}
@media (max-width: 600px){
    pagination-template .ngx-pagination{
        justify-content: center;
        margin-top: 20px;
    }
    .ngx-pagination.responsive .small-screen{
        display: none!important;
    }
    .ngx-pagination.responsive li:not(.small-screen):not(.pagination-previous):not(.pagination-next) {
        display: block!important;
    }
}
/* Range slider */
.ngx-slider .ngx-slider-bubble{
    padding: 1px 5px!important;
    color: #ffffff!important;
    font-size: 10px!important;
    background-color: #1f1f1f;
    border-radius: 4px;
}
.ngx-slider .ngx-slider-bubble::after{
    position: absolute;
    display: block;
    content: "";
    bottom: -6px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: #1f1f1f;
}
.ngx-slider .ngx-slider-bar{
    background: #e1e4e9!important;
    height: 12px!important;
    border-radius: 4px!important;
}
.ngx-slider .ngx-slider-selection{
    background: #1f1f1f!important;
    border-radius: 0px!important;
}
.ngx-slider .ngx-slider-pointer:after {
    display: none!important;
}
.ngx-slider .ngx-slider-pointer{
    width: 2px!important;
    height: 18px!important;
    top: -3px!important;
    border: 0px solid #1f1f1f;
    background-color: #1f1f1f!important;
}

/* .hm-product-colors input:checked + span::before{
    background-color: #f3f3f3;
} */
@media (max-width: 768px){
    .hm-quicklook-body .hm-product-content{
        padding:2rem;
        margin-bottom: 0;
    }
}
.hm-quicklook .modal-dialog {
    max-width: 870px;
}