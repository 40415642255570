	/*
  	Flaticon icon font: Flaticon
  	Creation date: 01/05/2020 12:44
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {
  font-family: Flaticon;
font-style: normal;
}

.flaticon-piggy-bank:before { content: "\f100"; }
.flaticon-clock:before { content: "\f101"; }
.flaticon-credit-card:before { content: "\f102"; }
.flaticon-free-delivery:before { content: "\f103"; }
.flaticon-right-quote:before { content: "\f104"; }
.flaticon-shopping-cart:before { content: "\f105"; }
.flaticon-like:before { content: "\f106"; }
.flaticon-search:before { content: "\f107"; }
.flaticon-tshirt:before { content: "\f108"; }
.flaticon-dress:before { content: "\f109"; }
.flaticon-table:before { content: "\f10a"; }
.flaticon-bed:before { content: "\f10b"; }
.flaticon-creative:before { content: "\f10c"; }
.flaticon-bodysuit:before { content: "\f10d"; }
.flaticon-pants:before { content: "\f10e"; }
.flaticon-rocking-horse:before { content: "\f10f"; }
.flaticon-play-button:before { content: "\f110"; }
.flaticon-underwear:before { content: "\f111"; }
.flaticon-bad-weather:before { content: "\f112"; }
.flaticon-diamond:before { content: "\f113"; }
.flaticon-watch:before { content: "\f114"; }
.flaticon-clothes:before { content: "\f115"; }
.flaticon-fire:before { content: "\f116"; }
